@import "src/globals";

.modalSlide {
  &-body {
    padding: 0 !important;

    .react-slideshow-container {

      .default-nav {
        &:first-child {
          position: relative;
          left: 10px;
          background: $BASE_BLUE;

          svg {
            fill: white;
            margin-left: 1px;
          }
        }

        &:last-child {
          position: relative;
          right: 10px;
          background: $BASE_BLUE;

          svg {
            fill: white;
            margin-right: -5px;
          }
        }
      }

    }
  }
}
