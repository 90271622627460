@import "src/globals";

.grid-list-items {
  height: $HEIGHT_LIST;

  .btn-export{
    background: $BASE_BLUE;
  }

  .card-header {
    padding: 0 20px 0 0 !important;
  }

  .dx-g-bs4-toolbar {
    padding: 0 20px 0 0 !important;
    background: transparent;

    button {
      border-radius: 0;
      background-color: $BASE_BLUE;
      min-width: 100px;
      color: white;

      &:after {
        content: "EXPORTAR";
      }

      height: 40px;
    }
  }
}
