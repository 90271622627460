@import "src/globals";

.bookmarks {
  .breadcrumb {
    font: normal normal 400 13px/20px $fontFamily;
    text-transform: uppercase;
    background-color: white;
    border-bottom: 1px solid lightgrey;

    .breadcrumb-item {
      &.active {
        font-weight: 600;
        color: black;
      }

      a {
        color: #6c757d;

        &:hover {
          text-decoration: none;
        }
      }

      &:last-child {
        margin-right: 10px;
      }

      .count {
        margin-right: 10px;
      }
    }
  }
  .listBookmarksWrapper {
    padding: 3px;
    width: 100%;
    height: calc(100vh - 86px);
    overflow: auto;

    .table thead th:first-child, .table thead th:nth-child(2){
      width: 60px;
    }

    .table tbody tr td:first-child, .table tbody tr td:nth-child(2){
      text-align: center;
    }
  }
}
