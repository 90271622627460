@import "src/globals";

.legenda {
  position: absolute;
  z-index: 9000;
  bottom: 30px;
  right: 10px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: default;
  padding: 5px;

  .legend-icons {
    display: none;
    height: 20px;
    line-height: 20px;
    width: 100%;
    text-align: center;

    .fa {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .square {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    background-color: gray;
    opacity: 0.7;
  }

  .circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    border-radius: 40px;

    &.cn {
      background: #246db7
    }

    &.oe {
      background: #f9ba04
    }

    &.oer {
      background: #7b7c7b
    }
  }
}

.mapFilterZoom {
  border: 1px solid #ddd;
  z-index: 9000;
  top: 10px;
  left: 50px;
  position: absolute;
  background-color: white;
  text-align: center;
  padding: 5px;

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
    margin-bottom: 0;
    margin-right: 5px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: $BASE_BLUE;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px $BASE_BLUE;
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;

      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }
  }
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
}

.edificios-encontrados{
  height: 400px;
  width: 200px;
  background: white;
  position: absolute;
  right: 0;
  top: 200px;
  z-index: 9000;
  transition: right 1s;
  border: 1px solid #ddd;

  &.close{
    right: -200px;
  }
}

.leaflet-control-layers-overlays, .leaflet-control-layers-separator {
  display: none;
}
