@import "src/globals";

.small-wrapper {
  display: none;

  &.show {
    display: flex;
    flex-direction: column;
  }

  .small-toolbar {
    display: flex;
    padding: 10px 0;
    justify-content: space-around;
    border-bottom: 1px solid lightgrey;

    .btn-icon {
      background: transparent !important;
      border: none;
      color: black !important;
      &:focus{
        box-shadow: none !important;
      }

      &.active {
        color: #116db7 !important;
      }
    }
  }

  .counts {
    padding-top: 10px;
    font: 600 13px/20px $fontFamily;
    text-align: center;
  }
}
