.apps-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-left: 10px;
    #basic-button {
        width: 30px;
        min-width: 30px;
    }
}
