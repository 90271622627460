@import "src/globals";

.pipeline-navbar {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 60px;
  z-index: 1000;

  .nav-bar-items {
    width: 100%;
    height: 60px;

    .nav-link {
      text-transform: uppercase;
      text-align: left;
      font: 500 14px/20px $fontFamily;
      letter-spacing: 0;
      opacity: 1;
      line-height: 50px;

      &.active {
        color: $BASE_BLUE !important;
        border-bottom: 2px solid $BASE_BLUE;
      }
    }
  }
}

.main-content {
  display: flex;

  .filters-splitter {
    .layout-pane:first-child {
      max-width: 275px !important;
      width: 275px;

      .filterWrapper {
        height: $HEIGHT_O_NB;
        overflow: auto;
        overflow-x: hidden;
        flex: 1;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        max-width: 275px;

        &::-webkit-scrollbar {
          display: none;
        }

        .list {
          width: 275px;
          padding: 10px;

          .cleanFilters {
            float: right;
            margin-top: 5px;
          }

          .moreFiltersBtn,
          .cleanFilters {
            border: none;
            text-align: left;
            padding: 0;
            font: normal normal 600 13px/20px $fontFamily;
            letter-spacing: 0;
            color: $BASE_BLUE;
            text-transform: uppercase;
            background: transparent;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .information {
            font: 300 13px/20px $fontFamily;
          }

          .show-filters {
            display: block;

            .num-fogos {
              width: 100%;
              height: 33px;
              font: 300 13px/20px $fontFamily;
            }

            .label {
              width: 100%;
            }

            .react-datepicker-wrapper {
              float: right;
              width: 80%;
            }

            .sublabel {
              text-transform: none !important;
              text-align: left;
              margin-right: 10px;
              font: 300 13px/20px $fontFamily;
              letter-spacing: 0;
              color: #404040;
              opacity: 1;
            }

            .clean-areas {
              text-align: left;
              font: 300 13px/20px $fontFamily;
              letter-spacing: 0;
              color: #404040;
              opacity: 1;
            }

            label {
              text-align: left;
              font: 300 13px/20px $fontFamily;
              letter-spacing: 0;
              color: #404040;
              text-transform: uppercase;
              opacity: 1;
            }

            .dateMin,
            .dateMax {
              font: 300 11px $fontFamily;
              width: 100%;
              float: right;
            }

            .clearDates {
              float: right;
              margin-right: 5px;
            }

            .areaMin {
              width: 47%;
              margin-right: 10px;
              font: 300 13px/20px $fontFamily;
              border-color: #cccccc;
              border-style: solid;
            }

            .areaMax {
              width: 47%;
              font: 300 13px/20px $fontFamily;
              border-color: #cccccc;
              border-style: solid;
            }

            .btn {
              margin-top: 10px;
              font: 300 13px/20px Kanit;
            }

            .react-datepicker__input-container {
              input {
                border-color: #cccccc;
                border-style: solid;
              }
            }
          }



          .toggle-filters {
            display: none;
          }
        }

        .more-filters {
          border: none;

          .card-header {
            padding: 0;
            text-align: left;
            border: none;
            background: none;

            button {
              text-align: left;
              padding: 0;
              font: normal normal 600 13px/20px $fontFamily;
              letter-spacing: 0;
              color: $BASE_BLUE;
              text-transform: uppercase;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }

          .card-body {
            padding: 0;

            .label {
              width: 100%;
            }

            label {
              text-align: left;
              font: 300 13px/20px $fontFamily;
              letter-spacing: 0;
              color: #404040;
              text-transform: uppercase;
              opacity: 1;
            }

            .dateMin,
            .dateMax {
              font: 300 11px $fontFamily;
              width: 75%;
              float: right;
            }

            .clearDates {
              float: right;
              margin-right: 5px;
            }

            .areaMin {
              width: 47%;
              margin-right: 10px;
              font: 300 13px/20px $fontFamily;
            }

            .areaMax {
              width: 47%;
              font: 300 13px/20px $fontFamily;
            }

            .btn {
              margin-top: 10px;
              font: 300 13px/20px Kanit;
            }
          }
        }
      }
    }
  }

  .splitter-layout {
    &.map {
      .layout-pane:first-child {
        max-width: 70% !important;
      }
    }
  }

  .mapCardsActions {
    flex: 1;
    width: 100%;
    height: $HEIGHT_O_NB;
  }

  .mapCardsWrapper {
    flex: 1;
    //height: $HEIGHT_W_NB;
    height: $HEIGHT_O_NB;
    width: 100%;

    .splitterClass {
      height: $HEIGHT_O_NB;
    }

    & .leaflet-container {
      height: $HEIGHT_O_NB;
      //height: $HEIGHT_W_NB;
    }
  }

  #cardsBox {
    overflow: auto;
    height: calc(100vh - 138px);
  }

  .gridList {
    height: calc(100vh - 60px);
    //height: $HEIGHT_W_NB;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .topBarWrapper {
      border-left: none;
      border-bottom: 1px solid lightgray;
      height: 46px;

      .topBarActions {
        height: 46px;
        display: flex;

        .action-rigth {
          padding-top: 5px;
          flex: 1;

          .btn_export {
            float: right;
            margin-right: 5px;
            background: #116db7;
            border: none;
          }

          .searchfields {
            background: white;
            position: absolute;
            right: 0;
            z-index: 9000;
          }

          #search {
            float: right;
            align-items: center;
            display: flex;
            //max-width: 600px;
            width: 90%;
            margin-right: 10px;

            input {
              border: solid #ccc;
              border-width: 1px 0 1px 1px;
              border-radius: 2px 0 0 2px;
              flex: 1;
              font-size: 16px;
              font-weight: 200;
              height: 33px;
              margin: 0;
              min-width: 90px;
              padding: 5px;
              padding-left: 25px;
              -webkit-appearance: textfield;

              &:focus {
                outline: none !important;
              }
            }

            .moreSearch {
              background-color: transparent;
              fill: black;
              position: absolute;
              color: black;
              font-size: 9px;
              width: 25px;
            }

            svg {
              background-color: $BASE_BLUE;
              border-radius: 0 2px 2px 0;
              cursor: pointer;
              fill: white;
              height: 33px;
              min-width: 24px;
              width: 33px;
              padding: 0 7px;
              color: white;
            }
          }

          @media all and (min-width: 500px) {
            #search {
              input::placeholder {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    .rightPanelActions {
      height: 32px;
      width: 100%;
      z-index: 100;
      background-color: white;
      display: flex;

      .changeView {
        button {
          color: black;
          font: 600 13px/20px $fontFamily;
          letter-spacing: 0;
          text-transform: uppercase;
          opacity: 1;
          background-color: transparent;
          border: none;
          border-radius: 0;

          &:first-child {
            margin-left: 5px;
          }

          &.active {
            color: $BASE_BLUE;
            background-color: transparent;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .counts {
        padding-right: 5px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font: 600 13px/20px $fontFamily;

        button {
          color: black;
          font: 600 13px/20px $fontFamily;
          letter-spacing: 0;
          text-transform: uppercase;
          opacity: 1;
          background-color: transparent;
          border: none;
          border-radius: 0;

          &:first-child {
            margin-left: 20px;
          }

          &.active {
            color: $BASE_BLUE;
            background-color: transparent;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

    }

    .picsCharts {
      flex: 1;
      padding-top: 20px;

      .chartActions {
        display: flex;
        padding-left: 20px;
        padding-right: 20px;

        .chartType {
          flex: 1;
          text-align: left;
          font: 500 18px/20px $fontFamily;
          letter-spacing: 0;
          color: #404040;
          text-transform: uppercase;
          opacity: 1;
        }

        .actionsBtn {
          button {
            text-align: right;
            font: 300 13px/20px $fontFamily;
            letter-spacing: 0;
            opacity: 1;

            &.active {
              color: $BASE_BLUE;
            }
          }
        }
      }
    }

    .infiniteScroll {
      padding-top: 20px;
      overflow-x: hidden !important;

      .masonryContainer {
        margin: 0 auto;
      }

      .gridListCards {
        display: flex;
        justify-content: center;
      }
    }

    .listWrapper {
      padding: 15px;
      flex: 1;
      overflow: auto;
    }
  }
}

.Detail {
  display: flex;
  height: calc(100vh - 60px);

  .sideBarLeft {
    flex: 1;
    max-width: 360px;
    box-shadow: 0 3px 6px #00000029;
    height: 100%;

    .classe_energetica {
      width: 50px;
      height: 50px;
      // background: url("/static/media/classes_energeticas.png");
      float: right;

      &.classe_energetica_2 {
        background-position-x: -50px;
      }

      &.classe_energetica_3 {
        background-position-x: -100px;
      }

      &.classe_energetica_4 {
        background-position-x: -150px;
      }

      &.classe_energetica_5 {
        background-position-x: -200px;
      }
    }

    .refci {
      padding: 15px;
      height: 50px;
      text-align: left;
      font: 400 13px/20px Kanit;
      letter-spacing: 0;
      color: #404040;
      text-transform: uppercase;
      opacity: 1;

      svg {
        margin-right: 10px;
      }
    }

    .image {
      height: 240px;
      background-color: lightgray;

      .imagem {
        width: 100%;
        max-height: 240px;

        &.multi {
          cursor: pointer;
        }
      }
    }

    .btn-block {
      text-transform: uppercase;
      text-align: left;
      border-bottom: 1px solid lightgray;
      font: 400 13px/20px Kanit;
      letter-spacing: 0;
      color: #404040;
      opacity: 1;
      margin-top: 0;
      height: 45px;
      border-radius: 0;

      svg {
        float: right;
      }

      &.active {
        color: #116db7;
      }

      &:focus {
        border: none;
        outline: none;
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
      }
    }

    .btn-bookmark {
      padding: 20px;

      .btn-block {
        background-color: #116db7;
        border-bottom: none;
        color: white;
        text-align: center;
      }
    }
  }

  .detailContent {
    flex: 1;
    overflow: auto;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .dadosGerais,
    .agentes {
      padding: 70px;

      .tableTitle {
        font: 400 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
        text-transform: uppercase;
      }

      .tableLeft {
        width: 50%;
        text-align: left;
        font: 400 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
      }

      .tableRight {
        width: 50%;
        text-align: left;
        font: 300 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;

        &.link {
          cursor: pointer;
        }

        svg {
          float: right;
        }
      }
    }
  }

  .news {
    flex: 1;
    padding: 50px 30px 0 50px;

    &-title {
      font: 600 13px/20px Kanit;
      text-transform: uppercase;
    }

    &-body {
      font: 300 13px/20px Kanit;
    }
  }

  .fracoes,
  .ticket-container {
    flex: 1;
  }

  .info-comer {
    flex: 1;
    padding: 50px;
    padding-right: 100px;

    .logos {
      border: 1px solid #e8e8e8;
      width: 105px;
      height: 105px;
      margin-right: 10px;
      vertical-align: middle;
      display: flex;
      float: left;
      overflow: hidden;

      img {
        margin: auto;
        max-width: 105px;
      }
    }
  }

  .empreendimento {
    flex: 1;
    display: flex;
    overflow: auto;
    margin-bottom: 40px;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .dadosGerais,
    .agentes,
    .fracoes,
    .info-comer {
      padding: 70px;
      width: 100%;

      .tableTitle {
        font: 400 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
        text-transform: uppercase;
      }

      .tableLeft {
        width: 50%;
        text-align: left;
        font: 400 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;
      }

      .tableRight {
        width: 50%;
        text-align: left;
        font: 300 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        opacity: 1;

        &.link {
          cursor: pointer;
        }

        svg {
          float: right;
        }
      }

    }
  }

  .ticket-container {
    display: flex;
    padding: 70px;

    text-align: left;
    font: 300 13px/20px Kanit;
    letter-spacing: 0;
    color: #404040;

    label {
      text-align: left;
      font: 500 13px/20px Kanit;
      letter-spacing: 0;
      color: #404040;
      text-transform: uppercase;
      opacity: 1;
    }

    .info-text {
      flex: 1;
    }

    .form-container {
      flex: 1;

      .react-select {
        text-align: left;
        font: 300 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;

        &:focus {
          outline: none !important;
        }

        &__control {
          border-radius: 0;
          box-shadow: none;

          &:hover {
            border-color: unset;
          }

          &:focus {
            outline: none !important;
          }
        }

        &__menu {
          border-radius: 0;

          &:focus {
            outline: none !important;
          }
        }

        &__indicator-separator {
          display: none;

          &:focus {
            outline: none !important;
          }
        }
      }

      .message {
        margin-top: 10px;
        resize: none;
        text-align: left;
        font: 300 13px/20px Kanit;
        letter-spacing: 0;
        color: #404040;
        border-radius: 0;
      }

      button {
        margin-top: 15px;
        width: 200px;
        border-radius: 0;
        border: none;
        background-color: #116db7;
        text-align: center;
        font: 500 13px/20px Kanit;
        letter-spacing: 0;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;

        &:disabled {
          background-color: lightgrey;
        }
      }
    }

    .ticket-container-list {
      a {
        color: black;

        &:hover {
          text-decoration: none;
        }

        svg {
          margin-right: 15px;
        }
      }

      &-timeline {
        list-style-type: none;
        position: relative;

        &:before {
          content: " ";
          background: #d4d9df;
          display: inline-block;
          position: absolute;
          left: 29px;
          width: 2px;
          height: 100%;
          z-index: 400;
        }

        li {
          margin: 20px 0;
          padding-left: 20px;

          &:before {
            content: " ";
            background: white;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid #116db7;
            left: 20px;
            width: 20px;
            height: 20px;
            z-index: 400;
          }

          &.in_process:before {
            border: 3px solid #d60606;
          }
        }
      }
    }
  }
}