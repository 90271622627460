@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&display=swap');

$BASE_BLUE: #116DB7;
$fontFamily: 'Kanit', sans-serif;
$HEIGHT_O_NB: calc(100vh - 60px);
$HEIGHT_W_NB: calc(100vh - 106px);
$WIDTH_FIL: calc(100vw - 275px);
$HEIGHT_LIST: calc(100vh - 136px);

body {
  overflow: hidden;
}

.react-datepicker__month .react-datepicker__month-text{
  text-transform: capitalize;
}

.leaflet-grab {
  cursor: pointer !important;
}

.modal-status-process {
  .modal-header {
    justify-content: center;
  }

  .modal-body {
    text-align: center;

    .spinner-center {
      width: 5rem;
      height: 5rem;
    }
  }
}

.progressBar {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.react-select {
  font: normal normal 300 12px/20px $fontFamily;
  letter-spacing: 0;
  color: #404040;
  width: 99%;

  &:focus {
    outline: none !important;
  }

  &__control {
    border-radius: 0 !important;
    min-height: 30px !important;

    &--is-focused {
      outline: none !important;
      box-shadow: none !important;
    }

    &:focus {
      outline: none !important;
    }
  }

  &__value-container {
  }

  &__indicator-separator {
    display: none;
  }

  &__indicators {
  }

  &__menu {
    border-radius: 0 !important;

    &-list {
      max-height: 200px;
    }
  }

  &__option {

    &:hover {
      background: $BASE_BLUE !important;
      color: white !important;
    }

    &--is-focused {
      background: $BASE_BLUE !important;
      color: white !important;
    }
  }
}

.splitterClass {
  width: 100%;
  height: $HEIGHT_W_NB;
  position: relative;

  .layout-splitter {
    width: 13px;
    background-color: transparent;
    border: 1px solid lightgrey;

    &:after {
      content: "||";
      vertical-align: middle;
      line-height: $HEIGHT_W_NB;
      padding: 2px;
    }

    &:hover {
      background-color: $BASE_BLUE;
      color: white;
    }
  }

  &.hide {
    display: none
  }
}

.popover {
  max-width: 80% !important;
  width: 30%;
}

.popover-body {
  font: normal normal 400 13px/20px $fontFamily;

  label {
    text-transform: capitalize;
  }

  input {
    border: none;
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
  }

  .btn-search-actions {
    float: right;
    margin-right: 10px;
    background: $BASE_BLUE;
    border: none;
    margin-bottom: 10px;
  }
}

.disclaimer {
  font: 600 13px/20px Kanit;
  background: white;
  min-width: 55px;
  width: 55px;
  height: calc(100vh - 60px);
  transition: width 0.5s, min-width 0.5s;
  border: 1px solid lightgray;
  right: 0;
  top: 59px;
  position: absolute;

  &.open {
    width: 300px;
    min-width: 300px;

    .title {
      padding: 20px 20px 0 20px;
      display: block;
      cursor: pointer;
      font: 600 13px/20px Kanit;

      span {
        float: right;
      }
    }

    .openButon {
      display: none;
    }

    .disclaimer-content {
      width: 300px;
      padding: 20px;
      display: block;
      background-color: #fff;
      font: 300 12px/20px Kanit;
    }
  }

  .disclaimer-content {
    display: none;
  }

  .openButon {
    display: block;
    -webkit-writing-mode: tb-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: tb-rl;
    color: black;
    cursor: pointer;
    font: 600 13px/20px Kanit;
    position: relative;
    top: 30px;
    right: -15px;
    margin: 0;
  }

  .title {
    display: none;
  }
}
