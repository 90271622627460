@import "src/globals";

.CardComponent {
  margin-bottom: 10px;
  display: inline-block;
  border: 1px solid #c6c8ca;
  width: 300px;
  height: 350px;
  overflow: hidden;

  &.selected {
    border: 2px $BASE_BLUE dotted;
  }

  img {
    cursor: pointer;
    height: 200px;
  }

  &.over {
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.75);
  }

  .card-body {
    padding: 0;
    font-family: $fontFamily;
    font-weight: 300;
    font-size: 10pt;
    color: black;
    display: flex;

    .info-panel {
      flex: 1;
      padding: 10px;
      cursor: pointer;
    }

    .menu {
      flex: 1;
      display: flex;
      height: 100%;
      max-width: 38px;
      width: 38px;
      flex-direction: column;
      justify-content: space-around;
      border-left: 1px solid lightgray;

      &-btn {
        border-radius: 0;
        background: white;
        border: none;
        font-size: 18px;
        width: 100%;
        height: 100%;

        &:hover {
          font-size: 24px;
        }

        &:focus {
          outline: none;
        }

        &.disabled {
          cursor: default;
        }

        .classe_energetica {
          width: 35px;
          height: 25px;

          &.classe_energetica_1 {
            background-position-x: -5px !important;
          }

          &.classe_energetica_2 {
            background-position-x: -55px !important;
          }

          &.classe_energetica_3 {
            background-position-x: -105px !important;
          }

          &.classe_energetica_4 {
            background-position-x: -155px !important;
          }

          &.classe_energetica_5 {
            background-position-x: -205px !important;
          }
        }
      }
    }

    .data-info {
      margin-bottom: 10px;

      .title {
        font-weight: 500;
      }
    }

  }
}