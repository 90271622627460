@import "src/globals";

.loading {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  background-color: #80808070;
  .spinner-border {
    align-self: center;
    width: 4rem;
    height: 4rem;
    border: 0.45em solid $BASE_BLUE;
    border-right-color: transparent;
    border-radius: 50%;
  }
}
